<template>
  <Home></Home>
</template>

<script>
import Home from './pages/Home';

export default {
  name: 'App',
  components: {
      Home
  }
}
</script>